import { Auth } from "aws-amplify";

function Unauthorized() {
    return (
        <main className="flex-1 relative overflow-y-auto focus:outline-none">
            <div className="py-6">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                    <h1 className="text-2xl font-semibold text-gray-900">Unauthorized!</h1>
                </div>
                <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                    <h1>In order to access this site, you must be:</h1>
                    <ul>
                        <li>Administrator</li>
                    </ul>
                    <button
                        className="w-full text-white rounded bg-red-800 hover:bg-red-600"
                        onClick={() => Auth.signOut()}
                    >
                        Log Out
                    </button>
                </div>
            </div>
        </main>
    );
}
export default Unauthorized;
