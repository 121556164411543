/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    aws_project_region: "us-west-2",
    aws_appsync_graphqlEndpoint: "https://wxrlg4ncrrg7lmopxnlz4bzvbi.appsync-api.us-west-2.amazonaws.com/graphql",
    aws_appsync_region: "us-west-2",
    aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
    aws_cognito_region: "us-west-2",
    aws_user_pools_id: "us-west-2_ksqQ6U0b9",
    aws_user_pools_web_client_id: "4o3t7ptdhj0f3q2ate8q826ahq",
    aws_cognito_identity_pool_id: "us-west-2:1915eaac-d799-4108-8fc1-c0cafd709027",
    Auth: {
        region: "us-west-2",
        userPoolId: "us-west-2_ksqQ6U0b9",
        userPoolWebClientId: "4o3t7ptdhj0f3q2ate8q826ahq",
        identityPoolId: "us-west-2:1915eaac-d799-4108-8fc1-c0cafd709027",
    },
    Storage: {
        AWSS3: {
            bucket: "reliance-crm-data-artifacts-stage",
            region: "us-west-2",
        },
    },
    mandatorySignIn: true,
    oauth: {
        domain: "stage.auth.reliancelitigation.com",
        scope: ["email", "profile", "openid"],
        redirectSignIn: "https://stage.data.reliancelitigation.com/",
        redirectSignOut: "https://stage.data.reliancelitigation.com/",
        responseType: "code",
    },
};

export default awsmobile;
