import Confirm, { ModalInfo } from "../components/Confirm";
import Loader from "../components/Loader";

import { API, Storage } from "aws-amplify";
import { useStore } from "effector-react";
import { useState, useCallback, Fragment } from "react";
import { Link } from "react-router-dom";

import { getFileColumns, parseFile } from "reliance-private-data-schema/dist/graphql/mutations";
import { subscribeToAddRealtimeEvent } from "reliance-private-data-schema/dist/graphql/subscriptions";

import GenderStore, { resetGender, updateGenderStore } from "../stores/GenderStore";

import { Column, RealtimeEvent } from "reliance-private-data-schema/dist/API";

import { v4 as uuidv4 } from "uuid";

export default function Gender() {
    const gender_store = useStore(GenderStore);

    const [parsing, setParsing] = useState(false);
    const [modalInfo, setModalInfo] = useState<ModalInfo>({
        title: "Title",
        text: "Text",
        positive: "OK",
        negative: "Cancel",
        close: () => {
            setModalInfo({
                ...modalInfo,
                open: false,
            });
        },
        action: () => {},
        open: false,
    });

    const doDownload = useCallback(async () => {
        var url = await Storage.get(gender_store.completedFile!);
        window.location.href = String(url);
    }, [gender_store.completedFile]);

    const onColumnChoose = async (i: number) => {
        updateGenderStore({
            state: "PARSING",
            columnIndex: i,
        });
        await API.graphql({
            query: parseFile,
            variables: {
                fileId: gender_store.fileId!,
                columnIndex: i,
                isHeader: gender_store.isHeader,
            },
        });
    };

    const onFileChoose = async (e) => {
        const file = e.target.files[0];
        try {
            setParsing(true);
            var fileId = uuidv4();
            await Storage.put(fileId, file, {
                acl: "bucket-owner-full-control",
                progressCallback(progress) {
                    updateGenderStore({
                        percentage: ((progress.loaded / progress.total) * 100).toFixed(2),
                    });
                },
            });
            var getFileColumnsResult: any = await API.graphql({
                query: getFileColumns,
                variables: {
                    fileId: fileId,
                },
            });

            var fileColumns = (getFileColumnsResult.data.getFileColumns ?? []) as Column[];

            API.graphql({
                query: subscribeToAddRealtimeEvent,
                // @ts-ignore
            }).subscribe({
                next: ({ provider, value }: { provider: any; value: any }) => {
                    console.log(value);
                    if (value.data.subscribeToAddRealtimeEvent.fileId !== fileId) {
                        return;
                    }
                    /// new message received!
                    var rtm = value.data.subscribeToAddRealtimeEvent as RealtimeEvent;
                    var payload = JSON.parse(rtm.payload ?? "{}");
                    if (payload.complete) {
                        updateGenderStore({
                            state: "COMPLETE",
                            completedFile: payload.completedFile,
                        });
                        setParsing(false);
                    }
                },
                error: (error: any) => console.warn(error),
            });
            updateGenderStore({
                state: "PICK_FIELDS",
                fileId: fileId,
                columnsData: fileColumns!,
            });
            setParsing(false);
        } catch (err) {
            alert("Error!");
        }
    };
    return (
        <>
            <header className="bg-white shadow-sm">
                <div className="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8">
                    <h1 className="text-lg leading-6 font-semibold text-gray-900">Gender</h1>
                </div>
            </header>
            <main>
                <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
                    {gender_store.state === "INIT" && (
                        <div>
                            <div className="py-3">
                                {parsing ? (
                                    <>Please wait while your file is uploaded: {gender_store.percentage}%</>
                                ) : (
                                    <>
                                        <h2>
                                            Welcome to the <b>GENDER CSV</b> processing system!
                                        </h2>
                                        <p className="mt-6 text-sm">
                                            This system will accept <b>CSV</b> files with a <b>.csv</b> file extension
                                            for gender processing. Please convert any <b>Excel</b> files to <b>CSV</b>{" "}
                                            before using them with this system.
                                        </p>
                                        <p className="mt-3 text-sm">
                                            This system will append a <b>gender</b> column to the file containing our
                                            best guess at the gender (using the first name) from our database of over{" "}
                                            <b>2M</b> rows of name data.
                                        </p>
                                        <p className="mt-6 mb-6">
                                            Please upload a CSV file with a <b>first name</b> field included to get
                                            started.
                                        </p>
                                    </>
                                )}
                            </div>
                            <div>
                                {parsing ? (
                                    <Loader />
                                ) : (
                                    <>
                                        <input type="file" accept="text/csv" onChange={(evt) => onFileChoose(evt)} />
                                        <p className="text-red-500">
                                            Must be a <b>.csv</b> extension!!
                                        </p>
                                    </>
                                )}
                            </div>
                        </div>
                    )}
                    {gender_store.state === "PICK_FIELDS" && (
                        <>
                            <div className="py-3">
                                Is this first row a <b>HEADER</b> with column names?
                            </div>
                            <div className="py-3">
                                <label htmlFor="toggleB" className="flex items-center cursor-pointer">
                                    <div className="relative">
                                        <input
                                            type="checkbox"
                                            checked={gender_store.isHeader}
                                            onChange={() => {
                                                updateGenderStore({
                                                    isHeader: !gender_store.isHeader,
                                                });
                                            }}
                                            id="toggleB"
                                            className="sr-only"
                                        />

                                        <div className="block bg-gray-600 w-14 h-8 rounded-full"></div>

                                        <div className="dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition"></div>
                                    </div>

                                    <div className="ml-3 text-gray-700 font-medium">
                                        {gender_store.isHeader ? (
                                            <Fragment>
                                                Yes! It's a <b>Header Row</b>
                                            </Fragment>
                                        ) : (
                                            <Fragment>
                                                No, it's a <b>Data Row</b>
                                            </Fragment>
                                        )}
                                    </div>
                                </label>
                            </div>
                            <div className="py-3">
                                Please pick which column is the <b>first name</b> column
                            </div>
                            {gender_store.columnsData?.map((c, i) => (
                                <div key={i}>
                                    <button
                                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 mb-2 rounded-full w-1/3"
                                        onClick={() =>
                                            setModalInfo({
                                                ...modalInfo,
                                                open: true,
                                                title: "Process File",
                                                text: `Are you sure you wish to launch file processing with field: "${c!
                                                    .value!}"?`,
                                                action: () => {
                                                    onColumnChoose(i);
                                                },
                                            })
                                        }
                                    >
                                        {c!.value!}
                                    </button>
                                </div>
                            ))}
                        </>
                    )}
                    {gender_store.state === "PARSING" && (
                        <>
                            <div className="py-3">Please wait while your file is processed</div>
                            <Loader />
                        </>
                    )}
                    {gender_store.state === "COMPLETE" && (
                        <>
                            <div className="py-3">
                                <h2>Your file is ready!</h2>
                            </div>

                            <div>
                                <button
                                    className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 mb-2 rounded-full w-1/3"
                                    onClick={() => {
                                        doDownload();
                                    }}
                                >
                                    Download
                                </button>
                            </div>
                            <div className="py-3">
                                If you want to do another one, just{" "}
                                <Link
                                    to="/gender"
                                    onClick={() => resetGender()}
                                    className="text-blue-500 hover:underline"
                                >
                                    click here
                                </Link>
                                .
                            </div>
                        </>
                    )}
                </div>
            </main>
            {modalInfo.open && <Confirm info={modalInfo} />}
        </>
    );
}
